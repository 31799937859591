import React from 'react'
import { images } from 'config/images'

export const SoftwareTestComProps = {
  ppcbannerProps: {
    heading: (
      <>
        <span className="highlight-head">Software testing company</span> with a
        proven track record
      </>
    ),
    para: (
      <>
        With over 900+ clients served, Simform is an{' '}
        <span className="color-through">end-to-end QA company</span> lead by
        passionate testers who love to ship quality products
      </>
    ),
    formCTA: 'Get a Free Quote!',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Assuring quality products for
        <span className="highlight-head"> Fortune 1000 companies</span>
      </>
    ),
    buildAppPara: (
      <>
        Extend your team with a{' '}
        <span className="color-through">talented team of testers</span> and
        implement robust QA processes. Our mission is to help your development
        team focus more on writing code, releasing new features, and reducing
        development time.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Our Key Services as a<br />
        <span className="highlight-head">Software Testing</span> Company
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Automation Testing Services',
        serviceParagraph: (
          <>
            <strong>Agile automated testing</strong> using tools like Jenkins,
            Selenium, and Appium so that customers can minimize maintenance
            effort and costs.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Mobile Application Testing Services',
        serviceParagraph: (
          <>
            Core focus of our Mobile Apps Testing services is to help companies
            <strong> deliver features faster</strong> and improve experiences to
            their mobile users.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Mobile',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'Manual and Functional Testing Services',
        serviceParagraph: (
          <>
            Testing applications from the{' '}
            <strong> end-users’ perspective</strong> significantly improves the
            software’s experience, usability, and critical defects.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'Manual',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'Software Security Testing Services',
        serviceParagraph: (
          <>
            Identify and <strong> resolve security vulnerabilities</strong> in
            your system. We make sure that the system’s data is protected.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Software',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'DevOps (CI CD) and Agile Services',
        serviceParagraph: (
          <>
            Get the latest <strong> continuous integration</strong> and
            continuous delivery tools to optimize the infrastructure and deploy
            in a matter of minutes, not hours.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'DevOps',
        serviceClass: 'ice',
      },
      {
        id: '6',
        serviceHeading: 'Performance Testing Services',
        serviceParagraph: (
          <>
            <strong>Achieve optimum stability</strong>, responsiveness, and
            scalability in your applications with our Full-cycle performance
            testing services.
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Performance',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with <br />
        Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle:
          'End-to-End test coverage surfaces difficult bugs',
        workingBenefitsContent: (
          <>
            Complete test coverage helps you surface bugs and defects difficult
            to foresee. We perform <strong> all types of testing</strong> like
            Functional, GUI, Usability, Security, Database testing,
            Cross-platform, Cross-browser, Accessibility, etc
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Complete transparency with KPIs',
        workingBenefitsContent: (
          <>
            We always align your most important QA KPIs with project goals. You
            are always in control, with{' '}
            <strong>full access to QA reporting</strong>, which includes test
            results, test coverage, quality trends, sign-off reports, and more.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Automated tested builds for quicker deliveries',
        workingBenefitsContent: (
          <>
            <strong>Armed with DevOps tools</strong>, our team can automate the
            majority of the critical and time-consuming operations. We jointly
            architecture CI and CD flows with emphasis on improving both unit
            and regression test coverage.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Case studies on how{' '}
        <span className="highlight-head">software testing</span> helped these
        companies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },

  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/functional-testing/',
        featurecardTitle:
          'What is Functional Testing? Explained with Test Cases and Example',
      },
      {
        blogLink: 'https://www.simform.com/mobile-app-testing/',
        featurecardTitle:
          'Mobile App Testing: Challenges, Types and Best Practices',
      },
      {
        blogLink: 'https://www.simform.com/test-coverage/',
        featurecardTitle:
          'Why Test Coverage is an Important part of Software Testing?',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactCruben,
    personImg2x: images.contactCruben2x,
    personName: 'Ruben Ramirez',
    personDesignation:
      "Simplifying organization's digital transformation by building interactive software products.",
    personEmail: 'ruben@simform.com',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
